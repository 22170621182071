import { Injectable } from "@angular/core";
import { BaseService, ServiceName } from "../../../../services/base-service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { CommonDropdownService } from "../../../common/services/common-dropdown.service";
import { AlertService } from "../../../../services/utilities/alert.service";
import { NgxSpinnerService } from "ngx-spinner";
import { APIUrlToUse } from "../../../../enums/api-url-to-use.enum";
import { Observable, catchError, combineLatest, map, of, shareReplay } from "rxjs";
import { GetPracticeDropdown, GetRequestStatusDropdown, GetShipmentModeDropdown, Q2oPersonalTasksDropdown } from "../models/dropdown.model";
import { CommonDropdownResponse } from "../../../common/models/dropdown.model";
import { APIResponse } from "../../../common/models/api-response.model";
import { PersonalTasks } from "../../../../enums/personal-tasks.enum";
import { Q2oPersonalGetInboxDraftDelegateSentRequest, Q2oPersonalGetInboxDraftDelegateSentResponse, RedirectUrlEformResponse } from "../models/q2o-personal-task.model";
import { Store } from "@ngrx/store";

@Injectable({
    providedIn: "root",
})
export class Q2oPersonalTasksService extends BaseService {
    constructor(
        private http: HttpClient,
        private commonDropdownService: CommonDropdownService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
        private store: Store,
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3002", ServiceName.q2o);
    }

    private readonly url = {
        practiceDropdown: "/q2o-request/common-dropdown/practice-dropdown",
        shipmentModeDropdown: "/q2o-request/common-dropdown/shipmentmode-dropdown",
        requestStatusDropdown: "/q2o-request/common-dropdown/requeststatus-dropdown",
        getInbox: "/q2o-request/get-inbox",
        getDraft: "/q2o-request/get-draft",
        getDelegate: "/q2o-request/get-delegate",
        getSent: "/q2o-request/get-sent",
        redirectUrlEform: "/q2o-request/redirect-url-eform",
        actionBtnLink: "/q2o-request/action-btn-link",
        reCreateOrderLink : "/q2o-request/redirect-url-re-create-order",
        reOrderTransactionLink : "/q2o-request/redirect-url-order-transaction",
        reBulkLink : "/q2o-request/redirect-url-bulk",
    };

    private get getPracticeDropdownUrl(): string {
        return this.prepareUrl(this.url.practiceDropdown);
    }
    private get getShipmentModeDropdownUrl(): string {
        return this.prepareUrl(this.url.shipmentModeDropdown);
    }
    private get getRequestStatusDropdownUrl(): string {
        return this.prepareUrl(this.url.requestStatusDropdown);
    }
    private get getInboxUrl(): string {
        return this.prepareUrl(this.url.getInbox);
    }
    private get getDraftUrl(): string {
        return this.prepareUrl(this.url.getDraft);
    }
    private get getDelegateUrl(): string {
        return this.prepareUrl(this.url.getDelegate);
    }
    private get getSentUrl(): string {
        return this.prepareUrl(this.url.getSent);
    }
    private get redirectUrlEformUrl(): string {
        return this.prepareUrl(this.url.redirectUrlEform);
    }
    private get actionBtnLinkUrl(): string {
        return this.prepareUrl(this.url.actionBtnLink);
    }

    private get reCreateOrderUrl(): string {
        return this.prepareUrl(this.url.reCreateOrderLink);
    }

    private get reOrderTransactionUrl(): string {
        return this.prepareUrl(this.url.reOrderTransactionLink);
    }

    private get reBulkrUrl(): string {
        return this.prepareUrl(this.url.reBulkLink);
    }

    practice$: Observable<GetPracticeDropdown[]> = this.http.get<APIResponse<GetPracticeDropdown[]>>(this.getPracticeDropdownUrl).pipe(
        map((i) => i.data),
        shareReplay(1),
    );
    shipmentMode$: Observable<GetShipmentModeDropdown[]> = this.http.get<APIResponse<GetShipmentModeDropdown[]>>(this.getShipmentModeDropdownUrl).pipe(
        map((i) => i.data),
        shareReplay(1),
    );
    requestStatus$: Observable<GetRequestStatusDropdown[]> = this.http.get<APIResponse<GetRequestStatusDropdown[]>>(this.getRequestStatusDropdownUrl).pipe(
        map((i) => i.data),
        shareReplay(1),
    );
    maxResult$: Observable<CommonDropdownResponse[]> = of(
        Array.from({ length: 5 }, (v, i) => {
            const number: number = (i + 1) * 10;
            return {
                name: `${number}`,
                code: `${number}`,
            };
        }),
    );

    getPersonalTasksDropdown(): Observable<Q2oPersonalTasksDropdown> {
        this.spinner.show();
        return combineLatest({
            practice: this.practice$,
            shipmentMode: this.shipmentMode$,
            requestStatus: this.requestStatus$,
            maxResult: this.maxResult$,
        }).pipe(
            map((v) => {
                this.spinner.hide();
                return {
                    practice: v.practice,
                    shipmentMode: v.shipmentMode,
                    requestStatus: v.requestStatus,
                    maxResult: v.maxResult,
                };
            }),
            catchError((err: HttpErrorResponse) => {
                this.spinner.hide();
                this.alertService.technicalError(err?.error?.message);
                return of({
                    practice: [],
                    shipmentMode: [],
                    requestStatus: [],
                    maxResult: [],
                });
            }),
        );
    }

    search(mode: PersonalTasks, request: Q2oPersonalGetInboxDraftDelegateSentRequest): Observable<APIResponse<Q2oPersonalGetInboxDraftDelegateSentResponse[]>> {
        let url = '';
        switch (mode) {
            case PersonalTasks.inbox:
                url = this.getInboxUrl;
                break;
            case PersonalTasks.draft:
                url = this.getDraftUrl;
                break;
            case PersonalTasks.delegate:
                url = this.getDelegateUrl;
                break;
            case PersonalTasks.sent:
                url = this.getSentUrl;
                break;
            default:
                url = this.getInboxUrl;
                break;
        }
        return this.http.post<APIResponse<Q2oPersonalGetInboxDraftDelegateSentResponse[]>>(url, request);
    }

    redirectUrlEform(requestId: number, processId: string): Observable<APIResponse<RedirectUrlEformResponse>> {
        let queryParams = this.convertObjectToHttpQueryParams({ request_id: requestId, process_id: processId });
        return this.http.get<APIResponse<RedirectUrlEformResponse>>(this.redirectUrlEformUrl, { params: queryParams });
    }

    actionBtnLink(requestId: number): Observable<APIResponse<string>> {
        let queryParams = this.convertObjectToHttpQueryParams({ request_id: requestId });
        return this.http.get<APIResponse<string>>(this.actionBtnLinkUrl, { params: queryParams });
    }

    actionReCreateOrder(): Observable<APIResponse<RedirectUrlEformResponse>> {
        return this.http.get<APIResponse<RedirectUrlEformResponse>>(this.reCreateOrderUrl);
    }

    actionReOrderTransactionOrder(): Observable<APIResponse<RedirectUrlEformResponse>> {
        return this.http.get<APIResponse<RedirectUrlEformResponse>>(this.reOrderTransactionUrl);
    }

    actionReBulkOrder(): Observable<APIResponse<RedirectUrlEformResponse>> {
        return this.http.get<APIResponse<RedirectUrlEformResponse>>(this.reBulkrUrl);
    }
}
